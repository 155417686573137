

import React, { Component } from "react";
import { connect } from 'react-redux' 
  

const mapStateToProps = (state) => {
 
    if (!state.pricebook || !state.pricebook.json) return {};
    
    return  { 
        pricebook : state.pricebook.json      
    } 
  } 
  
export class ExportPricebookPage extends Component {
    constructor(props) {
        super(props);     
      }

      async componentDidMount() {
         
      }

      render() { 
        return <textarea rows="100" cols="75">{JSON.stringify(this.props.pricebook, null, " ")}</textarea>
      } 
}

export default connect(
    mapStateToProps 
  )(ExportPricebookPage)
   