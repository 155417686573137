
import React, { Component } from "react";
import { connect } from 'react-redux'  
// import MatrixPricingControl from "../components/controls/MatrixPricingControl"   
import { getBaseCharge } from "../libs/pricebookSelector"; 
import { FREQUENCIES, TEXT_SIZE } from "../constants" 
// import PlanSelectControl from "../components/plan/PlanSelectControl"
import { loadPricingEdit, pricingHidePlan, pricingRemoveHidePlan, createPricingEdit, editPricingField, savePricingEdit, resetPricing, reorderRows, hideRow, addRow, renameRow }  from "../actions/pricingEditActions";
import { PLAN_ORDER } from '../constants' 
import { PencilIcon } from '@heroicons/react/solid' 
import Dropdown from "../controls/dropdownControl"
import SelectListControl from "../controls/selectListControl"
import {formatFrequency2 } from "../libs/formatHelpers"  
import ColorControl from "../controls/colorControl"  
import EditPricingRowsControl from "../controls/editPricingRowsControl" 

import AssetPlanConfigControl from "../controls/assetPlanConfigControl"  

import * as pricingHelper from '../libs/pricingHelper'

const mapStateToTemplateProps = (state, ownProps) => {
  
  const templates = ownProps.templates ? ownProps.templates: [];
  const templateMap =  [];// templates.map( t=> ({name: t.name, key: t.id }) );
  const currentTemplate = ownProps.currentName;

  templates.forEach(t => {
    templateMap.push({name: t.displayName, key: t.id})
  })
 
  return  {
    //[{name:'Cards', key: 'annual'}, {name:'Monthly', key: 'monthly'}, {name:'One Time', key: 'onetime'}],
    items: templateMap, 
    currentItem: currentTemplate
  }
}

const mapDispatchToFrequencyeProps = (dispatch,ownProps) => {
  return { 
    onSelect: (eventKey) => { 
      editPricingField('templateId', eventKey)  
    }
  }
}

 


const mapStateToEditRowsProps = (state) => {

  if (!state.pricebook || !state.pricebook.json || !state.pricingEdit || !state.pricingEdit.data ) return {};

  const defaultRows = pricingHelper.getDefaultPricingRowOrder(state.pricebook.json.products, state.pricebook.json.plans);

  // const items = state.pricingEdit ? state.pricingEdit.rows.filter( r => (r.type === "Section" ||defaultRows.find( dr => pricingHelper.compareRows(r, dr))))  : [];
  const items = state.pricingEdit &&  state.pricingEdit.rows ? state.pricingEdit.rows  : [];
  const availableItems = [{name: "Section", key: "Section"}];

  // console.log(defaultRows)
  defaultRows.forEach(row => {

    const existingRow = items.find(row2 => pricingHelper.compareRows(row, row2));

    if (!existingRow) {
      availableItems.push({name: row.name, key: row})
      // console.log('adding', row.name);
    }  else {
      existingRow.name = row.name;
    }

  }); 

  
  return  {  
      pricebook: state.pricebook.json, 
      availableItems,
      items
    }
  }


const mapDispatchToEditRowsProps = (state) => {
  return { 
    setRowConfig: (value) =>{ editPricingField('rowConfig', value) },
    hideHandler: (index) => { hideRow(index) },
    addHandler: (row) => { addRow(row); }, 
    renameHandler: (index, value) => { renameRow(index, value) },
    reorderHandler: (from, to) => {
      // console.log(`reorder from ${from} to ${to}`);
      reorderRows(from, to);
 
    }
  }
  
  }
   
  const MyEditPricingRowsControl = connect( mapStateToEditRowsProps, mapDispatchToEditRowsProps )(EditPricingRowsControl);



const PricingTemplateDropdown = connect(
  mapStateToTemplateProps,
  mapDispatchToFrequencyeProps )(Dropdown)


  // Sort order 

  const mapStateToSortOrderProps = (state, ownProps) => {
  
 
  return  { 
    items: Object.values(PLAN_ORDER).map( po => ({name: po, key: po }) ), 
    currentItem: ownProps.currentName
  }
}

const mapDispatchToSortOrderProps = (dispatch,ownProps) => {
  return { 
    onSelect: (eventKey) => { 
      editPricingField('planOrder', eventKey)  
    }
  }
}

const PricingSortOrderDropdown = connect(
  mapStateToSortOrderProps,
  mapDispatchToSortOrderProps )(Dropdown)

  
const mapStateToProps = (state) => {

  if (!state.pricebook || !state.pricebook.json || !state.pricingEdit || !state.pricingEdit.data ) return {};
  
  return  {  
      pricebook: state.pricebook.json, 
      rowConfig : state.pricingEdit.data.rowConfig? state.pricingEdit.data.rowConfig  : []
    }
  }


const mapDispatchToProps = (state) => {
  return { 
    setRowConfig: (value) =>{ editPricingField('rowConfig', value) } ,
    
  }
  
  }
   
  // const ProductMatrixPricingControl = connect( mapStateToProps, mapDispatchToProps )(MatrixPricingControl);

  



const mapPSStateToProps = (state) => {

  if (!state.pricebook || !state.pricebook.json  || !state.pricingEdit || !state.pricingEdit.data ) return {};

  const activePlans = state.pricebook.json.plans.filter( p => p.active);
  const hiddenPlans = state.pricingEdit.data.hiddenPlans ? state.pricingEdit.data.hiddenPlans : [];

  activePlans.forEach(plan => {
    // plan.hidden = hiddenPlans.includes(plan.id);
    plan.hidden = hiddenPlans.find(hp => hp.id === plan.id);
  });
  
  return  {  
      plans: activePlans
    }
  }

  const mapPlansDispatchToProps = (state) => {
    return { 
      changeHandler: (key, value) => {
        if (value) {
          pricingRemoveHidePlan(key)
        } else {
          pricingHidePlan(key)
        } 
      } 
    }
    
    }


const mapPSDispatchToProps = (state) => {
  return { 
    hidePlan: (value) =>{ pricingHidePlan(value) },
    showPlan: (value) =>{ pricingRemoveHidePlan(value) },
  }
  
  }
   
  // const PricingPlanSelectControl = connect( mapPSStateToProps, mapPSDispatchToProps )(PlanSelectControl);


const getEntry = (caption, html) => {


  return <>
  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-t  border-gray-200">
        <dt className="text-sm font-medium text-gray-500">{caption}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

          <div className="flex  justify-start   text-center max-w-3xl ">
            {html}
          </div> 
        </dd>
      </div>
    </>
} 

export class AssetDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.saveHandler = this.saveHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);

    // console.log(this.props.fontFamily);
    this.state = {
      activeFontFamily:this.props.fontFamily,
      openModal: false
  };
}

  async componentDidMount() {
  
    if (this.props.match.params.templateId) {
      createPricingEdit(this.props.match.params.templateId);
    } else {
      loadPricingEdit(this.props.match.params.assetId);
    }
    
    this.setState({
      activeFontFamily: this.props.fontFamily,
  })
  }

  cancelHandler() { 
    this.props.history.push('/pricing')
  }

  saveHandler() { 
    this.props.history.push('/pricing');
  }
  

  render() {

    const mapPlansStateToProps = (state) => {

      if (!state.pricebook || !state.pricebook.json  || !state.pricingEdit || !state.pricingEdit.data ) return {};

      const activePlans = state.pricebook.json.plans.filter( p => p.active)
            .sort(function (a, b) {
              return formatFrequency2(getBaseCharge(a)).localeCompare(formatFrequency2(getBaseCharge(b)));
          }).map(p => ({
            key: p.id, 
            configCallback : () => 
              this.setState({openModal: true, planId: p.id, 
                ctaCaption : pricingHelper.getCtaConfig(state.pricingEdit.data.ctaConfig, p.id).ctaCaption, 
                ctaUrl : pricingHelper.getCtaConfig(state.pricingEdit.data.ctaConfig, p.id).ctaUrl}), 
            name: `${p.name} ${formatFrequency2(getBaseCharge(p))}` }));
      const hiddenPlans = state.pricingEdit.data.hiddenPlans ? state.pricingEdit.data.hiddenPlans : [];

      activePlans.forEach(plan => {
        // plan.hidden = hiddenPlans.includes(plan.id);
        plan.enabled = !hiddenPlans.find(hp => hp.id === plan.key);
      });
      
      
      return  {  
          items: activePlans
        }
      }


     
        
    const PlanSelectListControl = connect( mapPlansStateToProps, mapPlansDispatchToProps )(SelectListControl);

    const styles = {
      title: "Tint Color",
      labelStyle: {
        paddingBottom: "7px"
      },
      colorTextBoxStyle: {
        height: "35px",
        border: "none",
        borderBottom: "1px solid lightgray",
        paddingLeft: "35px"
      }
    };

    return (
      <>

         
        <div className="max-w-7xl bg-white m-auto p-10 border rounded-md ">
        <div>
          <div className="flex justify-between	">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Editing {this.props.name}</h3>
            <div>
                <a
                  onClick={() => this.props.cancelEdit()}
                  className="font-medium px-6 text-gray-600 hover:text-gray-500"
                >Cancel</a>          
                <button
                  type="button"
                  onClick={() => this.props.saveEdit(() => this.props.cancelEdit())}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
              Save
            </button>
            </div>
          </div>

          {/* <Button disabled={this.props.isBusy} size="lg" className="floatRight rightAlign btn btn-custom-default" onClick={() => this.props.saveEdit(this.props.cancelEdit)}>Save</Button>
         */}
        </div>

        <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">             
          {getEntry('Asset Name', 
                  <>
                  <input
                    type="text"
                    name="name"
                    value={this.props.name}
                    onChange={(event) => { editPricingField('name', event.target.value)}}
                    id="name"
                    className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="Type asset name"
                    />
                   <PencilIcon className="h-5 w-5" aria-hidden="true" />
                   </>
          )}

          {getEntry('Url Slug', 
                  <>
                   <input
                    type="text"
                    name="urlSlug"
                    value={this.props.urlSlug}
                    // onChange={(event) => { editPricingField('slug', event.target.value)}}
                    onChange={(event) => { editPricingField('urlSlug', event.target.value)}}
                    id="name"
                    className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter url slug for your page"
                    />
                   <PencilIcon className="h-5 w-5" aria-hidden="true" />
                   </>
          )}          
          
          {getEntry('CTA Caption', 
                  <>
                  <input
                  type="text"
                  name="ctaCaption"
                  value={this.props.ctaCaption} 
                  onChange={(event) => { editPricingField('ctaCaption', event.target.value)}}
                  id="ctaCaption"
                  className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                  placeholder="Enter caption for your CTA buttons"
                  />
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                   </>
          )}

          {getEntry('CTA Url', 
                  <>
                  <input
                    type="text"
                    name="ctaUrl"
                    value={this.props.ctaUrl} 
                    onChange={(event) => { editPricingField('ctaUrl', event.target.value)}}
                    id="ctaUrl"
                    className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter url for your CTA buttons"
                    />
                   <PencilIcon className="h-5 w-5" aria-hidden="true" />
                   </>
          )}

          {getEntry('Contact Url', 
                  <>
                  <input
                    type="text"
                    name="contactUsUrl"
                    value={this.props.contactUsUrl} 
                    onChange={(event) => { editPricingField('contactUsUrl', event.target.value)}}
                    id="contactUsUrl"
                    className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter url for your Contact Us buttons"
                    />
                   <PencilIcon className="h-5 w-5" aria-hidden="true" />
                   </>
          )}

          {getEntry('Popular Caption', 
                  <>
                  <input
                    type="text"
                    name="mostPopular"
                    value={this.props.mostPopularCaption}
                    onChange={(event) => { editPricingField('mostPopularCaption', event.target.value)}} 
                    id="mostPopular"
                    className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter most popular plan catipon"
                    />
                   <PencilIcon className="h-5 w-5" aria-hidden="true" />
                   </>
          )}
          {getEntry('Plan Template', <PricingTemplateDropdown currentName={this.props.currentTemplateName} templates={this.props.templates}/>)}
          {getEntry('Plan Order', <PricingSortOrderDropdown currentName={this.props.planOrder}  />)}

          {getEntry('Most Popular Annual Plan', 
            <Dropdown 
            currentItem={this.props.popularYearlyPlanTitle} 
            items={[{name: 'None', key: null}].concat(this.props.annualPlans.map(p => ({name:p.name, key:p.id}))) }
            
            onSelect={(eventKey) => editPricingField('popularYearlyPlanId', eventKey) }
            />
          )}
          {getEntry('Most Popular Monthly Plan', 
            <Dropdown 
            currentItem={this.props.popularMonthlyPlanTitle} 
            items={[{name: 'None', key: null}].concat(this.props.monthlyPlans.map(p => ({name:p.name, key:p.id}))) }
            onSelect={(eventKey) => editPricingField('popularMonthlyPlanId', eventKey) }
            />
          )}
           {getEntry('Excluded Items', 
            <Dropdown 
            currentItem={this.props.excludedItems}             
            items={[{name: 'Cross-out', key: false}, {name: 'Hide', key: true}] }
            onSelect={(eventKey) => editPricingField('hideExcluded', eventKey) }
            />
          )}

          {getEntry('Plan Text Size', 
            <Dropdown 
            currentItem={this.props.nameTextSize}  
            items={[{name: 'Normal', key: TEXT_SIZE.NORMAL}, {name: 'Large', key: TEXT_SIZE.LARGE}, {name: 'Extra Large', key: TEXT_SIZE.XLARGE}] }
            onSelect={(eventKey) => editPricingField('nameTextSize', eventKey) }
            />
          )}

          {getEntry('Update Mode', 
            <Dropdown 
            currentItem={this.props.updateMode} 
            
            items={[{name: 'Auto', key: true}, {name: 'Manual', key: false}] }
            onSelect={(eventKey) => editPricingField('autoUpdate', eventKey) }
            />
          )}
          {getEntry('Plans', <PlanSelectListControl/> )}
          {getEntry('Tint Color', <ColorControl  
            color={this.props.tintColor}
            onColorChange={ (color)=> editPricingField('tintColor', color.hex)}
          />)}  
          {getEntry('CTA Color', <ColorControl  
            color={this.props.ctaColor}
            onColorChange={ (color)=> editPricingField('ctaColor', color.hex)}
          />)}  
          {getEntry('Background Color', <ColorControl  
            color={this.props.backgroundColor}
            onColorChange={ (color)=> editPricingField('backgroundColor', color.hex)}
          />)}       
          {getEntry('Included Symbol Color', <ColorControl  
            color={this.props.includeSymbolColor}
            onColorChange={ (color)=> editPricingField('includeSymbolColor', color.hex)}
          />)}   
          {getEntry('Excluded Symbol Color', <ColorControl  
            color={this.props.excludeSymbolColor}
            onColorChange={ (color)=> editPricingField('excludeSymbolColor', color.hex)}
          />)}    

          {getEntry('Pricing Rows', <MyEditPricingRowsControl />)}    
          </dl>
        </div>
        
        <AssetPlanConfigControl 
          open={this.state.openModal} 
          planId={this.state.planId} 
          ctaCaption={this.state.ctaCaption} 
          ctaUrl={this.state.ctaUrl} 
          changeHandler={ (key, value) =>  key === 'ctaCaption' ? this.setState( { ctaCaption : value}) : this.setState( { ctaUrl : value} )}
          saveModalHandler={ () => { editPricingField('ctaConfig', ({planId :this.state.planId, ctaCaption: this.state.ctaCaption, ctaUrl: this.state.ctaUrl}) ); this.setState({openModal: false})} }
          closeModalHandler={ () => this.setState({openModal: false})}/>
      </div>
       
      </>
    )
  }
 
}

const mapPricingDispatchToProps = (dispatch, ownProps) => {
  const callback = () => ownProps.history.push('/assets');
  return { 
    saveEdit: () =>{   savePricingEdit(callback); },
    cancelEdit: callback,
    resetPricing: () => resetPricing()
  }
}

const mapPricingStateToProps = state => {
 
  if (!state.pricingEdit || !state.pricingEdit.data ) { return { annualPlans: [], monthlyPlans: []} }

  const popularMonthlyPlan = state.pricebook.json.plans.find(p=> p.id === state.pricingEdit.data.popularMonthlyPlanId);
  const popularYearlyPlan = state.pricebook.json.plans.find(p=> p.id === state.pricingEdit.data.popularYearlyPlanId);
  const currentTemplate = state.pricingEdit.data.templateId ? 
    state.pricingPages.specs.find(spec => spec.id === state.pricingEdit.data.templateId) : null;

  const currentTemplateName = currentTemplate ? currentTemplate.displayName : null; 
  const tintColorDefault = currentTemplate ? currentTemplate.tintColor : null;
  const backgroundColorDefault = currentTemplate ? currentTemplate.backgroundColor : null;
  
  const includeSymbolColorDefault = currentTemplate ? currentTemplate.includeSymbolColor : null;
  const excludeSymbolColorDefault = currentTemplate ? currentTemplate.excludeSymbolColor : null;

  let nameTextSize = 'Normal';
  switch (state.pricingEdit.data.nameTextSize) {
    case TEXT_SIZE.LARGE:
      nameTextSize = 'Large';
      break;
    case TEXT_SIZE.XLARGE:
      nameTextSize = 'Extra Large';
      break;
  }

  return  { 
    pageName: "Edit Pricing", 
    name: state.pricingEdit.data.name,
    urlSlug: state.pricingEdit.data.urlSlug,
    ctaUrl: state.pricingEdit.data.ctaUrl,
    contactUsUrl: state.pricingEdit.data.contactUsUrl,
    ctaCaption: state.pricingEdit.data.ctaCaption,
    mostPopularCaption :  state.pricingEdit.data.mostPopularCaption,
    currentTemplate: state.pricingEdit.data.templateId,
    currentTemplateName,
    planOrder: state.pricingEdit.data.planOrder,
    updateMode: state.pricingEdit.data.autoUpdate ? "Auto" : "Manual",
    nameTextSize,
    excludedItems: state.pricingEdit.data.hideExcluded ? "Hide" : "Cross-out",
    templates: state.pricingPages.specs ? state.pricingPages.specs : [],
    includeSymbolColor : state.pricingEdit.data.includeSymbolColor, 
    includeSymbolColorDefault,
    excludeSymbolColor : state.pricingEdit.data.excludeSymbolColor,
    excludeSymbolColorDefault,
    backgroundColor : state.pricingEdit.data.backgroundColor, 
    textColor : state.pricingEdit.data.textColor,
    tintColor : state.pricingEdit.data.tintColor,
    ctaColor :  state.pricingEdit.data.ctaColor,
    tintColorDefault,
    backgroundColorDefault,
    fontFamily : state.pricingEdit.data.fontFamily,
    monthlyPlans: state.pricebook.json.plans.filter( p=> p.active && getBaseCharge(p).frequency === FREQUENCIES.MONTHLY),
    annualPlans : state.pricebook.json.plans.filter( p=> p.active && getBaseCharge(p).frequency === FREQUENCIES.YEARLY),
    popularMonthlyPlanTitle : popularMonthlyPlan ? popularMonthlyPlan.name : "Select Plan",
    popularYearlyPlanTitle : popularYearlyPlan ? popularYearlyPlan.name : "Select Plan" 
  }
}

export default connect(
  mapPricingStateToProps,
  mapPricingDispatchToProps
)(AssetDetailsPage)
