 
import { Fragment } from 'react'
import React  from 'react'; 
import Routes from "./Routes";
import { Menu, Popover, Transition } from '@headlessui/react'
import { PAGES } from './constants';
import { connect } from 'react-redux'
import PricebooksDropdown from './controls/pricebookDropdown'
import { getUrlFromPage, getBreadcrumbsPricebookDropdown } from './libs/selectors'

import { 
  HomeIcon 
} from '@heroicons/react/solid'
import { BellIcon, MenuIcon, XIcon, UserIcon } from '@heroicons/react/outline'

const mapStateToProps = (state, ownProps) => {

  if (!state.pricebooks || !state.pricebooks.items) { return { pricebookLoading: true }}

  return {
    pricebooks: state.pricebooks.items.filter( p => p.active),
    lastError:  state.pricebooks.lastError,
    currentName: state.pricebook && state.pricebook.json ? state.pricebook.json.name : "Select",
    pricebookLoading: state.pricebook ? state.pricebook.isLoading : false
  }
}

const ActivePricebooksDropdown = connect(
  mapStateToProps
)(PricebooksDropdown)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
   

  export const loadingContent = () => {
    return <>
              <div class="flex items-center justify-center h-screen">
                <div className="max-w-7xl bg-white m-auto p-10 align-middle  ">Loading...</div>
              </div>
            </>
  }
 
  // this function layers on menu
  export const authContent = (breadcrumbs, page, pricebookSelected, showPricebookSelector, signOutHandler) => 
  {
    if (page === PAGES.EXPORT) {
      return <Routes childProps={{isAuthenticated: true}} />
    }
    const userNavigation = [
      // { name: 'Your Profile', href: '#' },
      { name: 'Settings', href: '/profile'},
      { name: 'Sign out', callback:()=> signOutHandler(), href: '#' },
    ]   

    console.log(pricebookSelected);
    const navigation = pricebookSelected  && showPricebookSelector ? [  
      { name: 'Products', href: '/products', page: PAGES.PRODUCTS },
      { name: 'Plans', href: '/plans', page: PAGES.PLANS }, 
      { name: 'Assets', href: '/assets', page: PAGES.ASSETS },
    ] : [];

    return ( <>
      <div className="relative min-h-screen bg-gray-100">
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <Popover className="flex justify-between h-16">
              <div className="flex px-2 lg:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <a href="/">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-blue-600.svg"
                      alt="Workflow"
                    />
                  </a>
                </div>
                <nav aria-label="Global" className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                  {navigation.map((item) => (
                     item.page === page ?
                      <a key={item.name} href={item.href} className="px-3 py-2 text-gray-900 font-bold text-sm font-medium">
                        {item.name} 
                      </a>
                       : 
                      <a key={item.name} href={item.href} className="px-3 py-2 text-gray-900 text-sm font-medium">
                        {item.name} 
                      </a>                  
                    
                  ))}
                </nav>
              </div>
              <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
               
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Transition.Root as={Fragment}>
                <div className="lg:hidden">
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay className="z-20 fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
                  </Transition.Child>
  
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                    >
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                        <div className="pt-3 pb-2">
                          <div className="flex items-center justify-between px-4">
                            <div>
                              <img
                                className="h-8 w-auto"
                                src="https://tailwindui.com/img/logos/workflow-mark-blue-600.svg"
                                alt="Workflow"
                              />
                            </div>
                            <div className="-mr-2">
                              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                <span className="sr-only">Close menu</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className="mt-3 px-2 space-y-1">
                            {navigation.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                              >
                                {item.name}
                              </a>
                            ))}
                          </div>
                        </div>
                        <div className="pt-4 pb-2">
                            <div className="mt-3 px-2 space-y-1">
                              <a href='/pricebooks' className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                              Pricebooks
                              </a>
                            </div>
                          <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                              {/* <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" /> */}
                            </div>
                            <div className="ml-3">
                              {/* <div className="text-base font-medium text-gray-800">{user.name}</div>
                              <div className="text-sm font-medium text-gray-500">{user.email}</div> */}
                            </div>
                            <button
                              type="button"
                              className="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              <span className="sr-only">View notifications</span>
                              <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                          <div className="mt-3 px-2 space-y-1">
                            {userNavigation.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                              >
                                {item.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                { showPricebookSelector && 
                <div className="px-4">
                  <ActivePricebooksDropdown/>
                </div>
                }
                <button
                  type="button"
                  className="flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
  
                {/* Profile dropdown */}
                <Menu as="div" className="ml-4 relative flex-shrink-0">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      <span className="sr-only">Open user menu</span>
                      {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                      <UserIcon className="block h-6 w-6 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              onClick={item.callback}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </Popover>
          </div>
  
          {breadcrumbs && 
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="border-t border-gray-200 py-3">
              <nav className="flex" aria-label="Breadcrumb">        
                <div className="hidden sm:flex">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {breadcrumbs ?
                        <>
                        { getBreadcrumbsPricebookDropdown(page) &&
                             <li>
                             <div className="flex items-center">
                              <svg
                                 className="flex-shrink-0 h-5 w-5 text-gray-300"
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor"
                                 viewBox="0 0 20 20"
                                 aria-hidden="true"
                               >
                                 <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                               </svg>
                               {/* <span className="ml-4 text-sm text-gray-500 hover:text-gray-700">Pricebook</span> */}
                               <ActivePricebooksDropdown/>
                           </div>
                         </li>
                        }                   
                        {breadcrumbs.map((item) => (
                          <li key={item.name}>
                            <div className="flex items-center">
                              <svg
                                className="flex-shrink-0 h-5 w-5 text-gray-300"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                              </svg>
                              <a
                                href={getUrlFromPage(item.name)}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                aria-current={item.current ? 'page' : undefined}
                              >
                                {item.name}
                              </a>
                            </div>
                          </li>
                        ))}
                        </>
                      :
                      <>
                       <li>
                          <div className="flex items-center">
                              <svg
                                className="flex-shrink-0 h-5 w-5 text-gray-300"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                              </svg>                           
                                <a href="/pricebooks"><span className="ml-4 text-sm text-gray-500 hover:text-gray-700">Pricebooks</span></a>
                            </div>
                        </li>
                      </>}
                  </ol>
                </div>
              </nav>
            </div>
          </div>
        }
        </header>
  
        <main className="py-10">
         
          <Routes childProps={{isAuthenticated: true}} />
          
        </main>
      </div>
      </>)

  }
   
  