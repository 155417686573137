import React from "react";
import { Route, Switch } from "react-router-dom";
// import SegmentsPage from "./containers_old/pages/SegmentsPage"
import ProfilePage from "./pages/profilePage"
import CheckoutPage from "./pages/checkoutPage"
import ProfileEditPage from "./pages/profileEditPage"
import NewPricebookPage from "./pages/newPricebookPage"
import DashboardPage from "./pages/dashboardPage"
import AssetDetailsPage from "./pages/assetDetailsPage"
import PricebookDetailsPage from "./pages/pricebookDetailsPage"
import PricebookOverviewPage from "./pages/pricebookOverview"
import ProductMapPage from "./pages/productMapPage"
import ProductsPage from './pages/productsPage'
import ProductDetailsPage from './pages/productDetailsPage'
import PlanDetailsPage from './pages/planDetailsPage'
import PlansPage from './pages/plansPage'
import PricebooksPage from "./pages/pricebooksPage"
import SuccessPage from "./pages/successPage"
import AssetsPage from "./pages/assetsPage" 
import ExportPricebookPage from "./pages/exportPricebookPage"

const Routes = ({ childProps }) =>
  <Switch> 
    <Route path="/" exact component={DashboardPage} props={childProps} />
    <Route path="/dashboard/:assetId" exact component={DashboardPage} props={childProps} />
    <Route path="/new" exact component={NewPricebookPage} props={childProps} />
    <Route path="/dashboard" exact component={DashboardPage} props={childProps} />
    <Route path="/pricebooks" exact component={PricebooksPage} props={childProps} />
    <Route path="/pricebooks/details/:pricebookId" exact component={PricebookDetailsPage} props={childProps} />
    <Route path="/pricebooks/overview/:pricebookId" exact component={PricebookOverviewPage} props={childProps} />
    <Route path="/products" exact component={ProductsPage} props={childProps} /> 
    <Route path="/products/details/:productId" exact component={ProductDetailsPage} props={childProps} />
    <Route path="/products/map/:productId" exact component={ProductMapPage} props={childProps} /> 
    <Route path="/plans" exact component={PlansPage} props={childProps} />
    <Route path="/plans/details/:planId" exact component={PlanDetailsPage} props={childProps} />   
    <Route path="/assets/details/:assetId" exact component={AssetDetailsPage} props={childProps} />
    <Route path="/assets" exact component={AssetsPage} props={childProps} />
    <Route path="/export" exact component={ExportPricebookPage} props={childProps} />
    
    {/* <Route path="/segments" exact component={SegmentsPage} props={childProps} /> */}
    <Route path="/profile/" exact component={ProfilePage} props={childProps} /> 
    <Route path="/profile/t/:tabId" exact component={ProfilePage} props={childProps} /> 
    <Route path="/profile/edit" exact component={ProfileEditPage} props={childProps} />  
    <Route path="/checkout" exact component={CheckoutPage} props={childProps} />   
    <Route path="/checkout/success" exact component={SuccessPage} props={childProps} />   
  </Switch>;

export default Routes;